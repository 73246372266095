import useGetFile from 'legacy/lib/api/hooks/useGetFile';
import React from 'react';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MyDropzone from 'legacy/templates/ui-kit/MyDropzone';
import { Component } from 'legacy/lib/api/types/Component';

const ImageUploader = ({ component }: { component: Component }) => {
	const methods = useFormContext();
	const { setValue } = methods;

	const { data: editItemImage, isFetching } = useGetFile(
		component?.primaryImageId as string,
		{
			enabled: !!component?.primaryImageId,
		}
	);

	useEffect(() => {
		if (editItemImage && !isFetching) {
			setValue('image', editItemImage);
		}
	}, [editItemImage, setValue, isFetching]);

	return (
		<div
			className={
				isFetching
					? 'flex-1 lg:flex-[0.5] tw-self-center tw-flex tw-justify-center tw-h-full'
					: 'flex-1 lg:flex-[0.5]'
			}
		>
			<Controller
				control={methods.control}
				name="image"
				render={({ field }) => (
					<MyDropzone
						doNotShowConfirmationModal
						isLoading={isFetching}
						setImageOnEveryChange
						image={field.value}
						onRemoveFiles={() => {
							methods.setValue('isImageDirty', true);
							field.onChange(null);
						}}
						onAcceptedFiles={(val: Blob) => {
							methods.setValue('isImageDirty', true);
							field.onChange(val);
						}}
					/>
				)}
			/>
		</div>
	);
};

ImageUploader.displayName = 'ImageUploader';

export default ImageUploader;

import React from 'react';
import { FeatureFlags } from 'legacy/app/enums/featureFlags/featureFlags';
import URI from 'legacy/defaults/RoutesDefault';
import { useHeader } from 'legacy/hooks/useHeader';
import { useFeature } from 'use-feature';
import {
	SECURITY_ATTRIBUTE_TYPES,
	useHasAccess,
} from 'legacy/app/context/security';

export const CheckbookOverviewHeader = () => {
	const showFeature = useFeature(FeatureFlags.CheckbookReconcile);
	const hasReconcileAccess = useHasAccess(
		60,
		SECURITY_ATTRIBUTE_TYPES.DenyAccess
	);
	const hasReconcileHistoryAccess = useHasAccess(
		60,
		SECURITY_ATTRIBUTE_TYPES.DenySpec1
	);

	const tabs = [
		{
			label: 'Checkbook Overview',
			to: URI.accountsPayable.checkbook.base,
			active: true,
			id: 'checkbook',
		},
	];

	if (showFeature && hasReconcileAccess) {
		tabs.push({
			label: 'Reconcile',
			to: URI.accountsPayable.checkbook.reconcile,
			active: false,
			id: 'reconcile',
		});
	}

	if (showFeature && hasReconcileHistoryAccess) {
		tabs.push({
			label: 'Reconcile History',
			to: URI.accountsPayable.checkbook.history,
			active: false,
			id: 'reconcile-history',
		});
	}

	useHeader({
		title: 'Checkbook',
		breadcrumbs: [
			{
				text: 'Accounts Payable',
				to: URI.accountsPayable.creditCard.list,
			},
			{
				text: 'Checkbook',
			},
		],
		tabs,
		enableBackButton: false,
	});
	return <></>;
};

CheckbookOverviewHeader.displayName = 'CheckbookOverviewHeader';

export enum ECacheKeys {
	JournalEntries = 'JournalEntries',
	CreditCard = 'CreditCard',
	FinanceCharges = 'FinanceCharges',
	Clients = 'Clients',
	StatusCodes = 'StatusCodes',
	Proposals = 'Proposals',
	Invoices = 'Invoices',
	ItemTotals = 'ItemTotals',
	PurchaseOrders = 'PurchaseOrders',
	VendorInvoices = 'VendorInvoices',
	Reconciliations = 'Reconciliations',
	TemporaryReconciliations = 'TemporaryReconciliations',
}

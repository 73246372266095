import React from 'react';
import { Table, Form } from 'react-bootstrap';

const CheckbookReconcileTotals = () => {
	return (
		<div className="table-gradient">
			<Table borderless className="">
				<thead>
					<tr key="0">
						<th className="fs-lg">
							Statement Balance as
							<br />
							of 11/10/2022
						</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Deposits in Transit</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Outstanding Checks</th>
						<th className="fs-lg"></th>
						<th className="fs-lg">Balance</th>
						<th className="fs-lg">
							Computer Balance as
							<br />
							of 11/10/2022
						</th>
						<th className="fs-lg">Difference</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
						<td className="align-middle text-secondary-ash">
							<i className="ri-add-line ri-lg"></i>
						</td>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
						<td className="align-middle text-secondary-ash">
							<span className="fsx-24">-</span>
						</td>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
						<td className="align-middle text-secondary-ash">
							<span className="fsx-24">=</span>
						</td>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
						<td>
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

CheckbookReconcileTotals.displayName = 'CheckbookReconcileTotals';

export { CheckbookReconcileTotals };

import { Component } from 'legacy/lib/api/types/Component';
import { Summary } from 'legacy/lib/api/types/Summary';
import { mapRtfToDraft } from 'legacy/utilities/Rtf';
import { TFormValues } from '../types/TFormValues';

const getPreloadValues = async (component: Component, address?: Summary[]) => {
	const {
		quantity,
		wc1dt,
		wc2dt,
		statusnote,
		cfadt,
		unitmeasure,
		itemName,
		supplier,
		woheader,
		useterms,
		bterm1,
		sidemark,
		unitlist,
		supdep,
		shipby,
		feecost,
		estmarkup,
		bterm2,
		comptype,
		list,
		bterm3,
		feeunitcost,
		usedisc,
		markup,
		wrspecinsrtf,
		wrspecins,
		estcost,
		estdisc,
		estprice,
		catalog,
		estunitcost,
		cfarequired,
		descOnly,
		specins,
		specinsrtf,
		descOnlyRtf,
		taxable,
	} = component;

	const addressByCode = address?.[0];

	const description = await mapRtfToDraft(descOnlyRtf);

	const newValues = {
		itemName,
		woheader,
		comptype,
		cfadt: cfadt ? new Date(cfadt).toISOString().split('T')[0] : '',
		wc1dt: wc1dt ? new Date(wc1dt).toISOString().split('T')[0] : '',
		wc2dt: wc2dt ? new Date(wc2dt).toISOString().split('T')[0] : '',
		statusnote: statusnote || '',
		estdisc: Number(estdisc),
		supdep: Number(supdep),
		quantity: Number(quantity),
		bterm1: Number(bterm1),
		bterm2: Number(bterm2),
		bterm3: Number(bterm3),
		estunitcost: Number(estunitcost),
		usedisc,
		cfarequired,
		sidemark,
		specinsrtf: {
			value: specins,
			html: await mapRtfToDraft(specinsrtf || ''),
		},
		wrspecinsrtf: {
			value: wrspecins,
			html: await mapRtfToDraft(wrspecinsrtf || ''),
		},
		shipby: shipby ? new Date(shipby) : null,
		markup: Number(markup),
		catalog: catalog || '',
		taxable,
		shipto: addressByCode ? addressByCode?.key : '',
		estcost: Number(estcost),
		isImageDirty: false,
		estmarkup: Number(estmarkup),
		estprice: Number(estprice),
		unitmeasure,
		feeunitcost: Number(feeunitcost),
		useterms,
		supplier: supplier || null,
		unitlist: Number(unitlist),
		list: Number(list),
		feecost: Number(feecost),
		description: {
			value: descOnly,
			html: description,
		} as TFormValues['description'],
	};

	return newValues;
};
export default getPreloadValues;

import WysiwygEditor from 'legacy/templates/ui-kit/WysiwygEditor';
import { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TFormValues } from '../types/TFormValues';
import Label from 'legacy/app/components/label/Label';
import { Form } from 'react-bootstrap';

const WorkroomSpecialInstructionsForm = () => {
	const methods = useFormContext<TFormValues>();
	const wrspecinsFormValue = methods.watch('wrspecinsrtf');
	const [wrspecinsrtf] = useState(wrspecinsFormValue.html);
	const woHeaderInput = methods.register('woheader');

	return (
		<>
			<div className="tw-flex tw-justify-between tw-items-center tw-p-6 tw-bg-[#EEEDE9] tw-rounded-md">
				<p className="tw-text-base tw-font-bold tw-m-0">
					Workroom Special Instructions
				</p>
			</div>
			<div className="tw-gap-8 tw-gap-y-6 tw-py-6 tw-pl-10 tw-grid tw-grid-cols-[150px_500px] tw-max-w-[1200px]">
				<Label label="Instructions" className="tw-pt-4 tw-justify-self-end" />
				<Controller
					control={methods.control}
					name="wrspecinsrtf"
					render={({ field }) => (
						<WysiwygEditor
							key={wrspecinsrtf}
							keepEmptySpaces
							triggerChangeOnLoad
							onChange={async (e: {
								target: {
									html: string;
									value: string;
								};
							}) => field.onChange(e.target)}
						>
							{/* We are using key and props to trigger a state change on the Editor because the
                            Editor internals are all messed up
                             */}
							{wrspecinsrtf}
						</WysiwygEditor>
					)}
				/>
				<div className="tw-col-end-[-1] tw-flex tw-items-center">
					<Form.Check
						label="Part of Work Order Heading"
						ref={woHeaderInput.ref}
						onChange={woHeaderInput.onChange}
						name={woHeaderInput.name}
						type="checkbox"
					/>
				</div>
			</div>
		</>
	);
};

WorkroomSpecialInstructionsForm.displayName = 'WorkroomSpecialInstructionsForm';
export default WorkroomSpecialInstructionsForm;

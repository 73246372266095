import React, { useState } from 'react';
import { Col, Row, Table, Form } from 'react-bootstrap';
import { fakeData } from 'legacy/templates/factory/fakeFactory';
import Switch from 'legacy/templates/components/Switch';

const CheckbookReconcileChecksTable = () => {
	const [data] = useState(fakeData(2));
	/* eslint-disable */
	const handleChecks = () => {};
	const handleOnSwitchChange = () => {};
	/* eslint-enable */
	return (
		<Col
			xs={12}
			lg={6}
			className="mb-3 mb-lg-0 position-relative table-action-bar"
		>
			<div className="bg-secondary-grey py-4 mb-4 roundedx-4">
				<Row className="align-items-center px-3">
					<Col className="px-3">
						<h6 className="fw-bold mb-0">Checks</h6>
					</Col>
					<Col>
						<div className="d-flex align-items-center justify-content-end">
							<Form.Label className="mb-0 mx-2" style={{ maxWidth: '100%' }}>
								<strong>Suppress Cleared</strong>
							</Form.Label>
							<span>
								<Switch checked={true} onChange={handleOnSwitchChange}></Switch>
							</span>
						</div>
					</Col>
				</Row>
			</div>

			<div className="table-gradient">
				<Table striped responsive className="a-table">
					<thead>
						<tr key="0">
							<th>Clr</th>
							<th>Sum</th>
							<th>Check #</th>
							<th>Date</th>
							<th>Amount</th>
							<th>Payee</th>
						</tr>
					</thead>
					<tbody>
						{data.map((item, i) => (
							<tr key={i}>
								<td>
									<Form.Check
										inline
										label=""
										name={`cclear-` + i}
										type="checkbox"
										data-id={i}
										id={`cclear-` + i}
										onChange={handleChecks}
									/>
								</td>
								<td>
									<Form.Check
										inline
										label=""
										name={`csum-` + i}
										type="checkbox"
										data-id={i}
										id={`csum-` + i}
										onChange={handleChecks}
									/>
								</td>
								<td>Vendor</td>
								<td>MM/DD/YYYY</td>
								<td>$300.00</td>
								<td>Lorem ipsum</td>
							</tr>
						))}
					</tbody>
				</Table>
			</div>
		</Col>
	);
};

CheckbookReconcileChecksTable.displayName = 'CheckbookReconcileChecksTable';

export { CheckbookReconcileChecksTable };

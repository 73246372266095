import React from 'react';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { FooterFormAction } from 'legacy/templates/components/Form';
import { CheckbookReconcileHeader } from 'legacy/templates/modules/accounts-payable/checkbook/CheckbookReconcile/CheckbookReconcileHeader';
import { CheckbookReconcileFilters } from './CheckbookReconcileFilters';
import { CheckbookReconcileTotals } from './CheckbookReconcileTotals';
import { CheckbookReconcileChecksTotals } from './CheckbookReconcileChecksTotals';
import { CheckbookReconcileDepositsTotals } from './CheckbookReconcileDepositsTotals';
import { CheckbookReconcileChecksTable } from './CheckbookReconcileChecksTable';
import { CheckbookReconcileDepositsTable } from './CheckbookReconcileDepositsTable';

const CheckbookReconcile = () => {
	return (
		<>
			<CheckbookReconcileHeader />

			<div className="content-padding has-action-bar">
				<Container fluid className="px-0">
					<Row>
						<Col sm="12">
							<CheckbookReconcileFilters />
							<CheckbookReconcileTotals />
						</Col>
					</Row>

					<Row className="py-2">
						<CheckbookReconcileChecksTable />
						<CheckbookReconcileDepositsTable />
					</Row>

					<Row>
						<CheckbookReconcileChecksTotals />
						<CheckbookReconcileDepositsTotals />
					</Row>
				</Container>
			</div>

			<FooterFormAction>
				<Button variant="primary" size="lg">
					Reconcile
				</Button>
			</FooterFormAction>
		</>
	);
};

CheckbookReconcile.displayName = 'CheckbookReconcile';

export { CheckbookReconcile };

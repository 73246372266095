import React, { createContext, useState } from 'react';

interface ICheckbookReconcileContext {
	started: boolean;
	setStarted: (value: boolean) => void;
}

export const CheckbookReconcileContext =
	createContext<ICheckbookReconcileContext>({
		started: false,
		setStarted: () => undefined,
	});

interface ICheckbookReconcileContextProviderProps {
	children: React.ReactNode;
}

const CheckbookReconcileProvider = ({
	children,
}: ICheckbookReconcileContextProviderProps) => {
	const [started, setStarted] = useState(false);

	return (
		<CheckbookReconcileContext.Provider
			value={{
				started,
				setStarted,
			}}
		>
			{children}
		</CheckbookReconcileContext.Provider>
	);
};

CheckbookReconcileProvider.displayName = 'CheckbookReconcileProvider';

export { CheckbookReconcileProvider };

import React, { useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import ListFilter from 'legacy/templates/components/ListFilter';
import DatePicker from 'react-datepicker';
import AccountsDropdown from 'legacy/pages/accounts-payable/credit-card/reconcile/AccountsDropdown';
import { FormInputAdOns } from 'legacy/templates/components/Form';
import { useController, useForm } from 'react-hook-form';
import SecureDropdownItem from 'legacy/app/components/security/SecureDropdownItem';
import { SECURITY_ATTRIBUTE_TYPES } from 'legacy/app/context/security';

const CheckbookReconcileFilters = () => {
	const [showTableSearch, setShowTableSearch] = useState(false);
	const [startDate] = useState(null);

	const { control } = useForm({
		defaultValues: {
			account: null,
		},
	});

	const { field } = useController({
		name: 'account',
		control,
		rules: { required: true },
	});

	const handleShowTableSearch = () => {
		setShowTableSearch((prevState) => !prevState);
	};

	/* eslint-disable */
	const handleOnDateChange = () => {};
	const handleOnAccountChange = () => {};
	/* eslint-enable */

	return (
		<>
			<ListFilter className="checkbook-list-filter">
				<ListFilter.Fields md={12} lg={12} xl={10} className="width-25">
					<ListFilter.Field>
						<Form.Label className="text-end">
							<strong>Account</strong>
						</Form.Label>
						<AccountsDropdown
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...field}
							className="tw-h-10"
							onChange={handleOnAccountChange}
						/>
					</ListFilter.Field>
					<ListFilter.Field className="col-lg-2">
						<Form.Label className="text-end">
							<strong>
								Statement
								<br />
								Ending Date
							</strong>
						</Form.Label>
						<div className="react-select-header">
							<DatePicker
								minDate={new Date()}
								selected={startDate}
								onChange={handleOnDateChange}
								showMonthDropdown={true}
								showYearDropdown={true}
								className="form-control form-control-sm"
								placeholderText="Select"
							/>
						</div>
					</ListFilter.Field>
					<ListFilter.Field className="col-lg-2">
						<Form.Label className="text-end">
							<strong>
								Statement
								<br />
								Ending Balance
							</strong>
						</Form.Label>
						<FormInputAdOns text="USD">
							<Form.Control type="text" placeholder="0.00" size="sm" />
						</FormInputAdOns>
					</ListFilter.Field>
					<Button variant="primary" size="sm" className="btn-icon ms-3">
						Start
					</Button>
				</ListFilter.Fields>
				<ListFilter.Actions md={12} lg={12} xl={2}>
					<ListFilter.Action>
						<Button
							variant="ivory"
							size="sm"
							className={`btn-icon btn-action ${
								showTableSearch ? 'bg-primary-ash text-white' : ''
							}`}
							onClick={handleShowTableSearch}
						>
							<i className="ri-search-line"></i> Search
						</Button>
					</ListFilter.Action>
					<ListFilter.Action className="ms-auto">
						<Dropdown className="d-flex justify-content-end ms-3" align="end">
							<Dropdown.Toggle
								id="dropdown-autoclose-true"
								variant="ivory"
								size="sm"
							>
								Actions
							</Dropdown.Toggle>

							<Dropdown.Menu>
								<SecureDropdownItem
									attributeNo={59}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec2}
								>
									<i className="ri-file-transfer-line"></i> Transfer
								</SecureDropdownItem>
								<SecureDropdownItem
									attributeNo={59}
									attributeType={SECURITY_ATTRIBUTE_TYPES.DenySpec1}
								>
									<i className="ri-eye-off-line"></i> Void
								</SecureDropdownItem>
								<Dropdown.Item href="">
									<i className="ri-eye-fill"></i> Show Details
								</Dropdown.Item>
								<Dropdown.Item href="">
									<i className="ri-file-chart-line"></i> Create Report
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</ListFilter.Action>
				</ListFilter.Actions>
			</ListFilter>
			<ListFilter.Spacer />
		</>
	);
};

CheckbookReconcileFilters.displayName = 'CheckbookReconcileFilters';

export { CheckbookReconcileFilters };
